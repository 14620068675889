.zile {
  min-height: 40px;
  background-color: #ededed;
  padding: 10px;
  min-width: 120px;
}

table {
  overflow: hidden;
  border-collapse: collapse;
  -moz-border-collapse: collapse; /* For old Mozilla browsers */
  -webkit-border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  clip: auto;
  border-radius: 12px !important;
  height: 100%;
  max-width: 1400px;
  margin-bottom: 40px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

th {
  padding: 8.5px;
  text-align: center;
  background-color: #ededed;
  width: 170px;
  font-weight: 400;
}

hr {
  width: 100%;
}

td {
  text-align: center;
  padding: 5px 12.5px;
  height: 100% !important;
  min-height: 40px !important;
  color: #0d4da1;
}

td:first-of-type {
  background-color: #ededed;
  width: 120px;
}

td:last-of-type {
  padding: 0;
}

sup {
  font-size: 12px;
}

td,
th {
  border: 1px solid #e0e0e0 !important;
}

a {
  text-decoration: none;
  color: #0d4da1;
  cursor: pointer;
}

.toolTipContainer {
  position: absolute;
  min-width: 200px;
  min-height: 50px;
  border: none;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  padding-left: 30px;
  margin-right: 10px;
  font-size: 14px;
  text-align: left;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: opacity 0.5 ease-in-out;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #0d4da1;
  }

  ul {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #0d4da1;
  }

  span {
    font-weight: 600;
    color: #0d4da1;
  }
}
