@font-face {
  font-family: 'USV', sans-serif;
  src: url('./resurse/fonturi/usv-light.woff') format('woff');
}

/* body {
  max-width: 1920px;
  margin: 0 auto;
  font-size: 16px;
} */

body {
  background-color: #fafbfb;
}

input,
textarea,
button,
select,
img,
a {
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  border-radius: 20px;
  border: transparent;
}

.containerPrincipal {
  max-width: 1920px;
  margin: 0 62px;
}

@media (max-width: 834px) {
  .containerPrincipal {
    margin: 0;
    background-color: #fafbfb;
    /* background-color: #f3f9f9; */
  }

  *::-webkit-scrollbar {
    width: 0px;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
    border: transparent;
  }
}

.css-148cpw5-control,
:hover,
:focus {
  outline: none !important;
}

.swiper-scrollbar {
  display: none;
}
